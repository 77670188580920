<template>
    <!-- HORIZONTAL LAYOUT -->
    <vx-card title="Approver Inter Storage Detail">
        <div class="vx-row mb-6">
            <vs-button
                class="ml-4 mt-2"
                color="danger"
                icon-pack="feather"
                icon="icon-arrow-left"
                @click="handleBack()"
            >Back</vs-button>
        </div>
        <div class="flex md:flex-row">
            <div class="w-full pr-6 md:basis-1/2">
                <h4>Selection</h4>
                <hr>
                <br>
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full">
                        <span>Inter Storage Document </span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <vs-input class="w-full" v-model="code" :readonly="true" />
                    </div>
                </div>
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full">
                        <span>Warehouse </span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <vs-input class="w-full" :value="warehouse ? warehouse.label : ''" :readonly="true" />
                    </div>
                </div>
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full">
                        <span>Type </span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <vs-input class="w-full" :value="type" :readonly="true" />
                    </div>
                </div>
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full">
                        <span>Source Storage Area</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <vs-input class="w-full" :value="srcWarehouseArea ? srcWarehouseArea.label : ''" :readonly="true" />
                    </div>
                </div>
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full">
                        <span>Destination Storage Area</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <vs-input class="w-full" :value="destWarehouseArea ? destWarehouseArea.label : ''" :readonly="true" />
                    </div>
                </div>
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full">
                        <span>Notes</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <vs-textarea v-model="notes" class="w-full" rows="3" name="notes" disabled></vs-textarea>
                    </div>
                </div>
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full">
                        <span>Reason</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <vs-textarea v-model="reason" class="w-full" rows="3" name="notes" :disabled="approvalStatus != 0"></vs-textarea>
                    </div>
                </div>
            </div>
            <div class="w-full md:basis-1/2">
                <div class="w-full pr-6 md:basis-1/2">
                    <h4>Attachments List</h4>
                    <hr>
                    <br>
                    <div class="vx-row mb-3 mt-6" style="margin-left: 0%">
                        <table class="vs-table vs-table--tbody-table">
                            <template>
                                <tr class="tr-values vs-table--tr tr-table-state-null selected" v-bind:key="tr.NameFile" v-for="(tr, i) in fileAttachment">
                                    <td class="td vs-table--td">{{ tr.NameFile }}</td>
                                    <td>
                                        <img :src="urlFile(tr)" style="width: 100px; height: 100px; object-fit: cover;" class="max-w-sm" @click="openPreview(i)" alt="">
                                    </td>
                                    <td class="td vs-table--td">
                                        <vx-tooltip text="Download" v-if="tr.PathFile != ''">
                                            <vs-button 
                                                type="line" 
                                                icon-pack="feather" 
                                                icon="icon-download" 
                                                :disabled="!tr.PathFile"
                                                @click.stop="download(tr)"
                                            />
                                        </vx-tooltip>
                                    </td>
                                </tr>
                            </template>
                        </table>
                    </div>
                    <vue-easy-lightbox
                        :visible="visible"
                        :imgs="images"
                        :index="index"
                        @hide="handleHide"
                    />
                </div>
            </div>
        </div>
        <br>
        <div class="flex md:flex-row">
            <div class="w-full">
                <h4>Moved Items</h4>
                <hr>
                <div class="vx-row pt-2"></div>
            </div>
        </div>
        <div class="mb-6 vx-row">
            <table width="100%" class="m-3 vs-table vs-table--tbody-table">
                <thead class="vs-table--thead">
                    <tr>
                        <th width="38%">SKU Description</th>
                        <th width="25%">Batch / Exp. Date</th>
                        <th width="10%">Moved Quantity</th>
                        <th width="12%">Unit</th>
                        <th width="7%">Quantity UOM</th>
                        <th width="8%">Total Qty (UOM)</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(tr, index) in skuLines" :key="index" style="padding-bottom: 5px">
                        <td class="td vs-table--td" style="padding: 5px">
                            <vs-input class="w-full" type="text" v-model="tr.sku_code" :readonly="true" />
                        </td>
                        <td class="td vs-table--td" style="padding: 5px">
                            <vs-input class="w-full" type="text" v-model="tr.batch" :readonly="true" />
                        </td>
                        <td class="td vs-table--td" style="padding-right: 10px">
                            <vs-input class="w-full" type="text" v-model="tr.qty" :readonly="true" />
                        </td>
                        <td class="td vs-table--td" style="padding-right: 5px">
                            <vs-input class="w-full" type="text" v-model="tr.unit" :readonly="true" />
                        </td>
                        <td class="td vs-table--td" style="padding-right: 10px">
                            <vs-input class="w-full" type="number" v-model="tr.qty_uom" :readonly="true" />
                        </td>
                        <td class="td vs-table--td" style="padding-right: 10px">
                            <vs-input class="w-full" type="number" v-model="tr.total_qty" :readonly="true" />
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="vx-row mb-6">
            <div class="vx-col sm:w-1/1 w-full">
                <div style="
                    position: absolute;
                    left: 50%;
                    -ms-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%);
                ">
                    <vs-button 
                        v-if="docStatus == 3 && approvalStatus == 0" 
                        class="ml-4 mt-2" 
                        color="success"
                        @click="handleDecision('approve')"
                    >Approve</vs-button>
                    <vs-button 
                        v-if="docStatus == 3 && approvalStatus == 0" 
                        class="ml-4 mt-2" 
                        color="danger"
                        @click="handleDecision('reject')"
                    >Reject</vs-button>
                    <vs-button 
                        v-if="docStatus == 3 && approvalStatus == 0" 
                        class="ml-4 mt-2" 
                        color="warning"
                        @click="handleDecision('inquiry')"
                    >Inquiry</vs-button>
                    <vs-button class="ml-4 mt-2" type="border" color="danger" v-on:click="handleBack()">Back</vs-button>
                </div>
            </div>
        </div>
    </vx-card>
</template>

<script>
import VueEasyLightbox from 'vue-easy-lightbox'

export default {
    components: {
        VueEasyLightbox
    },
    mounted() {
        this.id = this.$route.params.id
        this.tabOrder = this.$route.params.tab_order
        if (this.id) {
            this.getData(this.id);
        }
    },
    computed: {
        urlFile: () => {
            return (tr) => {
                return tr.File instanceof Blob ? URL.createObjectURL(tr.File) : tr.PathFile
            }
        }
    },
    watch: {},
    data() {
        return {
            approvePrompt: false,
            id: null,
            readonly: true,
            disabled: true,
            code: null,
            type: null,
            warehouse: null,
            srcWarehouseArea: null,
            destWarehouseArea: null,
            docStatus: 0,
            notes: "",
            skuLines: [
                {
                    line_id: 0,
                    sku_code: null,
                    batch: null,
                    qty: null,
                    unit: null,
                    qty_uom: 0,
                    total_qty: null,
                }
            ],
            rejected: 0,
            approvalStatus: 0,
            status: "",
            reason: "",
            tabOrder: 0,

            // attachments section
            fileAttachment: [],
            visible: false,
            images: [],
            index: 0,
        }
    },
    methods: {
        handleDecision(type) {
            if (type != "approve") {
                if (this.reason == "") {
                    this.$vs.dialog({
                        color: "danger",
                        title: "Error",
                        text: "Please fill the reason column",
                    });

                    return false
                }
            }

            this.$vs.dialog({
                type: "confirm",
                color: "danger",
                title: `Please Confirm`,
                text: "Are you sure to "+type+" this document?",
                accept: () => {
                    this.$vs.loading();
                    let param = {
                        reason: this.reason,
                        type: type,
                    }

                    this.$http.post("api/wms/v1/inter-storage/approval-decision/" + this.id, param)
                    .then(resp => {
                        if (resp.code == 200) {
                            this.$vs.loading.close();
                            this.$vs.dialog({
                                color: "success",
                                title: "Success",
                                text: "The status of this document has been updated",
                            });
                            this.handleBack()
                        } else {
                            this.$vs.loading.close();
                            this.$vs.dialog({
                                color: "danger",
                                title: "Something went wrong",
                                text: resp.message,
                            });
                        }
                    })
                    .catch((error) => {
                        this.$vs.loading.close();
                        console.log(error);
                        this.$vs.dialog({
                            color: "danger",
                            title: "Something went wrong",
                            text: error,
                        });
                    });
                }
            })
        },
        handleBack() {
            this.$router.push({
                name: "approval.inter-storage",
                params: {
                    tab_order: this.tabOrder,
                }
            });
        },
        handleUpdateFormat(value) {
            let cleanValue = value.toString().replace(/\D/g, '');
            return new Intl.NumberFormat('id-ID').format(cleanValue);
        },
        getData(id) {
            this.$vs.loading();
            this.$http.get("api/wms/v1/inter-storage/detail/"+id)
            .then((resp) => {
                if (resp.code == 200) {
                    this.id = resp.data.id
                    this.code = resp.data.code
                    this.docStatus = resp.data.status
                    this.type = resp.data.type
                    this.warehouse = resp.data.warehouse
                    this.srcWarehouseArea = resp.data.from_warehouse_area
                    this.destWarehouseArea = resp.data.to_warehouse_area
                    this.notes = resp.data.notes
                    this.reason = resp.data.reason
                    this.approvalStatus = resp.data.approval_status
                    this.skuLines = resp.data.sku_lines;
                    this.skuLines.forEach(val => {
                        val.qty = this.handleUpdateFormat(val.qty)
                        val.total_qty = this.handleUpdateFormat(val.total_qty)
                    })

                    if (resp.data.attachment) {
                        resp.data.attachment.forEach((val) => {
                            this.fileAttachment.push({
                                id: val.id,
                                NameFile: val.file_name,
                                PathFile: val.file_url,
                            });
                            this.images.push(val.file_url);
                        });
                    }
                }
                this.$vs.loading.close();
            })
            .catch((error) => {
                this.$vs.loading.close();
                console.log(error);
            });
        },

        // attachments section
        download(tr) {
            const link = document.createElement('a');
            link.href = this.urlFile(tr);
            link.download = tr.NameFile;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        },
        openPreview(index) {
            this.index = index;
            this.visible = true;
        },
        handleHide() {
            this.visible = false;
        },
    },
};
</script>
